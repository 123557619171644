import React from 'react';
import './MediaCarousel.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {VideoExtensions} from "../../constants/VideoExtensions";

class MediaCarousel extends React.Component{

    constructor(props) {
        super(props);
        this.slider = React.createRef();
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
    }

    play() {
        this.slider.slickPlay();
    }

    pause() {
        this.slider.slickPause();
    }

    isMediaTypeVideo(media) {
        let mediaIsVideo = false
        VideoExtensions.forEach(extension => {
            if (media.toLowerCase().includes(extension)) {
                mediaIsVideo = true;
            }
        });
        return mediaIsVideo;
    }

    isMediaTypeEmbed(url) {
        const youtubeMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]+)/);
        const vimeoMatch = url.match(/(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/);

        if (youtubeMatch) {
            return { type: "youtube", id: youtubeMatch[1] };
        }
        if (vimeoMatch) {
            return { type: "vimeo", id: vimeoMatch[1] };
        }
        return null;
    }

    renderDescription() {
        const {description} = this.props;

        if (!description) return null;

        const parser = new DOMParser();
        const doc = parser.parseFromString(description, "text/html");

        const parsedContent = Array.from(doc.body.childNodes).map((node, index) => {
            if (node.nodeName === "P" && node.childNodes.length === 1 && node.firstChild.nodeName === "A") {
                const url = node.firstChild.href;
                const embedData = this.isMediaTypeEmbed(url);

                if (embedData) {
                    return (
                        <div key={index} className="media-carousel-embed">
                            <iframe
                                src={
                                    embedData.type === "youtube"
                                        ? `https://www.youtube.com/embed/${embedData.id}`
                                        : `https://player.vimeo.com/video/${embedData.id}`
                                }
                                title="Embedded video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            />
                        </div>
                    );
                }
            }
            return <div key={index} dangerouslySetInnerHTML={{ __html: node.outerHTML }} />;
        });

        return <div className="media-carousel-description">{parsedContent}</div>;
    }

    render() {
        const {title, media} = this.props;

        const carouselSettings = {
            dots: true,
            autoplay: true,
            autoplaySpeed: 6000,
            pauseOnHover: false,
            pauseOnDotsHover: true, // Also pauses when clicked
            onSwipe: this.pause
        };

        return (
            <div>
                <Slider className={`media-carousel ${media?.filter(Boolean)?.length > 10 ? "smaller-dots" : ""}`} ref={slider => (this.slider = slider)} {...carouselSettings}>
                    {media.map((media, index) => {
                        if(media){
                            if(this.isMediaTypeVideo(media)){
                                return <div key={index} className="media-carousel-video-slide-container">
                                    <video className="media-carousel-video" autoPlay={true} loop={true} muted={true} playsInline={true}>
                                        <source src={media}/>
                                    </video>
                                </div>
                            } else {
                                return <div key={index} className="media-carousel-image-slide-container">
                                    <img className="media-carousel-image" src={media} alt=""/>
                                </div>
                            }
                        }
                        return null;
                    })}
                </Slider>

                {title &&
                    <div className="media-carousel-text-container">
                        <h2 className="media-carousel-title">{title}</h2>
                        {this.renderDescription()}
                    </div>
                }
            </div>
        );
    }
}

export default MediaCarousel;