import React from "react"
import {getI18n, withTranslation} from "react-i18next";
import './VideoAndText.css'
import Arrow from './../../resources/images/arrow-right.svg'
import {Link} from "react-router-dom";
import {VideoExtensions} from "../../constants/VideoExtensions";

class VideoAndText extends React.Component {

    isMediaTypeVideo(media) {
        let mediaIsVideo = false
        VideoExtensions.forEach(extension => {
            if (media.toLowerCase().includes(extension)) {
               mediaIsVideo = true;
            }
        });
        return mediaIsVideo;
    }

    isMediaTypeEmbed(media) {
        const youtubeMatch = media.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]+)/);
        const vimeoMatch = media.match(/(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/);

        if (youtubeMatch) {
            return { type: "youtube", id: youtubeMatch[1] };
        }
        if (vimeoMatch) {
            return { type: "vimeo", id: vimeoMatch[1] };
        }
        return null;
    }

    render() {

        const {title, description, media} = this.props;
        const trans = this.props.t;
        const embedData = this.isMediaTypeEmbed(media);

        return (
            <div className="video-and-text-wrapper">
                <div className={`video-and-text-container ${embedData ? "video-and-text-container--flex-start" : ""}`}>
                    {embedData ? (
                        <div className="video-and-text-embed">
                            <iframe
                                src={
                                    embedData.type === "youtube"
                                        ? `https://www.youtube.com/embed/${embedData.id}?&autoplay=1&mute=1&rel=0&controls=0&modestbranding=1&loop=1&playlist=${embedData.id}`
                                        : `https://player.vimeo.com/video/${embedData.id}?autoplay=1&muted=1&loop=1`
                                }
                                title="Embedded video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            />
                        </div>
                    ) : this.isMediaTypeVideo(media) ? (
                        <video
                            className="video-and-text-video"
                            autoPlay={true}
                            muted={true}
                            loop={true}
                            playsInline={true}
                        >
                            <source src={media}/>
                        </video>
                    ) : (
                        <img className="video-and-text-image" src={media} alt=""/>
                    )}
                    <div className="video-and-text-text-container">
                        <h2>{title}</h2>
                        <div className="video-text-description" dangerouslySetInnerHTML={{__html: description}}/>
                        <Link className="video-and-text-button" to={getI18n().language === 'en' ? "/en/about" : "/about"}>
                            <span>{trans("videoAndText.buttonText")}</span>
                            <img src={Arrow} alt="arrow-right"/>
                        </Link>
                    </div>
                </div>
            </div>
        );

    }
}

export default withTranslation()(VideoAndText)